const footerMenuList = [
  {
    id: 1,
    title: "Marketplace",
    diffClass: "md:col-start-7",
    list: [
      {
        id: 1,
        href: "#",
        text: "Coming soon...",
      },
    ],
  },
  {
    id: 2,
    title: "WOB token",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "https://app.swappi.io/#/swap",
        text: "Buy $WOB",
      },
      {
        id: 2,
        href: "https://dexscreener.com/conflux/0x0becdff7ec72da8898a9d023958432bab4984907",
        text: "Chart",
      },
    ],
  },
  {
    id: 3,
    title: "Age of APES",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "#",
        text: "Coming soon...",
      },
    ],
  },
];

const socialIcons = [
  {
    id: 1,
    href: "https://medium.com/@worldofbrickscm",
    text: "medium",
  },
  {
    id: 2,
    href: "https://twitter.com/WorldOBricks",
    text: "twitter",
  },
  {
    id: 3,
    href: "https://t.me/WorldofBrick",
    text: "telegram",
  },
];

export { footerMenuList, socialIcons };
