import Link from "next/link";
import { footerMenuList, socialIcons } from "../data/footer_data";

const footer = () => {
  return (
    <>
      {/* <!-- Footer --> */}

      <footer className="bg-white dark:bg-jacarta-900 page-footer">
        <div className="container">
          <div className="grid grid-cols-6 pt-24 pb-12 gap-x-7 gap-y-14 md:grid-cols-12">
            <div className="col-span-3 md:col-span-4">
              {/* <!-- Logo --> */}
              <Link href="#">
                <a className="inline-block mb-6">
                  <img
                    src="/images/logo-light.png"
                    className="max-h-7 dark:hidden"
                    alt="World of Bricks"
                  />
                </a>
              </Link>

              <Link href="#">
                <a className="inline-block mb-6 ">
                  <img
                    src="/images/logo-dark.png"
                    className="hidden mb-6 max-h-7 dark:block"
                    alt="World of Bricks"
                  />
                </a>
              </Link>
              <p className="mb-12 dark:text-jacarta-300">
                Create, sell and collect truly rare digital artworks. Powered by
                Conflux Network blockchain technology.
              </p>

              {/* <!-- Socials --> */}
              <div className="flex space-x-5">
                {socialIcons.map(item => {
                  const { id, href, text } = item;
                  return (
                    <Link href={href} key={id}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cursor-pointer group"
                      >
                        {text === "medium" && (
                          <svg
                            className="w-5 h-5 icon group-hover:fill-accent fill-jacarta-300 dark:group-hover:fill-white"
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            width="256"
                            height="256"
                            viewBox="0 0 256 256"
                          >
                            <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
                              <path
                                d="M 45 0 C 20.147 0 0 20.147 0 45 c 0 24.853 20.147 45 45 45 s 45 -20.147 45 -45 C 90 20.147 69.853 0 45 0 z M 70 25.993 l -3.992 3.827 c -0.344 0.262 -0.515 0.693 -0.444 1.12 v 28.12 c -0.071 0.427 0.099 0.858 0.444 1.12 l 3.898 3.827 v 0.84 H 50.299 v -0.84 l 4.038 -3.92 c 0.397 -0.397 0.397 -0.513 0.397 -1.12 V 36.237 L 43.506 64.754 h -1.517 L 28.917 36.237 V 55.35 c -0.109 0.804 0.158 1.612 0.724 2.194 l 5.252 6.371 v 0.84 H 20 v -0.84 l 5.252 -6.371 c 0.562 -0.582 0.813 -1.396 0.677 -2.194 V 33.25 c 0.062 -0.614 -0.172 -1.22 -0.63 -1.633 l -4.669 -5.624 v -0.84 h 14.496 l 11.204 24.573 l 9.851 -24.573 H 70 V 25.993 z"
                                transform=" matrix(1 0 0 1 0 0) "
                                stroke-linecap="round"
                              />
                            </g>
                          </svg>
                        )}
                        {text === "telegram" && (
                          <svg
                            className="w-5 h-5 icon group-hover:fill-accent fill-jacarta-300 dark:group-hover:fill-white"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 50 50"
                            width="50px"
                            height="50px"
                          >
                            <path d="M25,2c12.703,0,23,10.297,23,23S37.703,48,25,48S2,37.703,2,25S12.297,2,25,2z M32.934,34.375	c0.423-1.298,2.405-14.234,2.65-16.783c0.074-0.772-0.17-1.285-0.648-1.514c-0.578-0.278-1.434-0.139-2.427,0.219	c-1.362,0.491-18.774,7.884-19.78,8.312c-0.954,0.405-1.856,0.847-1.856,1.487c0,0.45,0.267,0.703,1.003,0.966	c0.766,0.273,2.695,0.858,3.834,1.172c1.097,0.303,2.346,0.04,3.046-0.395c0.742-0.461,9.305-6.191,9.92-6.693	c0.614-0.502,1.104,0.141,0.602,0.644c-0.502,0.502-6.38,6.207-7.155,6.997c-0.941,0.959-0.273,1.953,0.358,2.351	c0.721,0.454,5.906,3.932,6.687,4.49c0.781,0.558,1.573,0.811,2.298,0.811C32.191,36.439,32.573,35.484,32.934,34.375z" />
                          </svg>
                        )}
                        <svg className="w-5 h-5 icon group-hover:fill-accent fill-jacarta-300 dark:group-hover:fill-white">
                          <use xlinkHref={`/icons.svg#icon-${text}`}></use>
                        </svg>
                      </a>
                    </Link>
                  );
                })}
              </div>
            </div>

            {footerMenuList.map(single => (
              <div
                className={`col-span-full sm:col-span-3 md:col-span-2 ${single.diffClass}`}
                key={single.id}
              >
                <h3 className="mb-6 text-sm font-display text-jacarta-700 dark:text-white">
                  {single.title}
                </h3>
                <ul className="flex flex-col space-y-1 dark:text-jacarta-300">
                  {single.list.map(item => {
                    const { id, href, text } = item;
                    return (
                      <li key={id}>
                        <Link href={href}>
                          <a className="hover:text-accent dark:hover:text-white">
                            {text}
                          </a>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))}
          </div>

          <div className="flex flex-col items-center justify-between py-8 space-y-2 sm:flex-row sm:space-y-0">
            <span className="text-sm dark:text-jacarta-400">
              <span>
                © {new Date().getFullYear()} WorldOfBricks — Made with{" "}
              </span>
              <Link href="https://nextjs.org/">
                <a className="hover:text-accent dark:hover:text-white">
                  NextJS
                </a>
              </Link>
            </span>
          </div>
        </div>
      </footer>
    </>
  );
};

export default footer;
